import React from "react";
import "./App.css";
import GalleryPage from "./GalleryPage";

function App() {
  return (
    <GalleryPage />
  );
}

export default App;
