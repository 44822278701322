import { BigNumber, Contract, ethers, providers } from "ethers";
import { FC, useEffect, useState } from "react";
import { GalleryNFT } from "../typechain-types";

const contractJson = require("./assets/GalleryNFT.json");

const provider = new providers.AlchemyProvider(
  "matic",
  process.env.REACT_APP_ALCHEMY_API ?? ""
);
const contract = new ethers.Contract(
  process.env.REACT_APP_CONTRACT_ADDR ?? "",
  contractJson.abi,
  provider
) as GalleryNFT & Contract;

const GalleryItem: FC<{ tokenIdx: number }> = ({ tokenIdx }) => {
  const [tokenId, setTokenId] = useState("");
  const [metadata, setMetadata] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>(
    "https://placekitten.com/600/400"
  );
  useEffect(() => {
    contract
      .tokenByIndex(BigNumber.from(tokenIdx))
      .then((tokenId) => {
        setTokenId(tokenId.toString());
        return contract.tokenURI(tokenId);
      })
      .then((tokenUri) =>
        fetch(`https://ipfs.io/ipfs/${tokenUri.substring(7)}`)
      )
      .then((res) => {
        try {
          if (res.ok) {
            return res.json();
          }
          return {};
        } catch (e) {
          return {};
        }
      })
      .then((metadata: any) => {
        if (metadata?.["image"]) {
          setImageUrl(
            `https://ipfs.io/ipfs/${metadata?.["image"].substring(7)}`
          );
        }
        setMetadata(metadata);
      });
  }, []);
  return (
    <div className="">
      <div className="w-full p-1 md:p-2">
        <img
          alt="gallery"
          className="block object-cover object-center w-full h-full rounded-lg"
          src={imageUrl}
        />
      </div>
      <div className="block font-bold">
        {metadata?.["name"] && <h3 className="">{metadata["name"]}</h3>}
      </div>
      {metadata?.["name"] && metadata?.["description"] && (
        <p>{metadata["description"]}</p>
      )}
      <a href={`https://opensea.io/assets/matic/${process.env.REACT_APP_CONTRACT_ADDR}/${tokenId}`}>OpenSea</a>
    </div>
  );
};

const GalleryPage = () => {
  const [totalSupply, setTotalSupply] = useState(0);
  useEffect(() => {
    contract.totalSupply().then((tt) => {
      setTotalSupply(tt.toNumber());
    });
  }, []);

  const list = [...Array(totalSupply)];

  return (
    <section className="overflow-hidden text-gray-700 ">
      <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
        <div className="flex flex-wrap -m-1 md:-m-2">
          {list.map((n, idx) => (
            <GalleryItem key={idx} tokenIdx={idx} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default GalleryPage;
